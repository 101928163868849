import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, output, Output, ViewChild } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngxs/store';
import { UploadedDocumentComponent } from '@shared/components/uploaded-document/uploaded-document.component';
import { FileUploadMetadata } from '@shared/models/file-upload-metadata.model';
import { UploadedFileResponse } from '@shared/models/uploaded-file.model';
import { map, take } from 'rxjs/operators';
import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'hmt-document-upload-category',
  templateUrl: './document-upload-category.component.html',
  styleUrls: ['./document-upload-category.component.scss'],
  standalone: true,
  imports: [CommonModule, UploadedDocumentComponent, NgOptimizedImage, MatTooltipModule],
})
export class DocumentUploadCategoryComponent {
  @Input({ required: true }) categoryName!: string;
  @Input() viewOnly: boolean = false;
  @Input() files: UploadedFileType[] = [];
  @Input() uploadPath: string = 'frontend/testing'; // Default path
  @Input() otherOrdId: string = '';
  @Input() saveOneFileMode: boolean = false;
  @Input() canUpload: boolean = true;
  @Input() cannotUploadMessage: string = 'You are not authorized to upload files';

  @Output() fileUploaded = new EventEmitter<{ category: string; file: UploadedFileType }>();

  fileSaved = output<UploadedFileType>();

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private store: Store,
    private fileUploadService: FileUploadService
  ) {}

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  uploadFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const orgId = this.store.selectSnapshot(state => state.auth.user.orgId);
      const userName = this.store.selectSnapshot(state => `${state.auth.user.firstName} ${state.auth.user.lastName}`);

      const metadata: FileUploadMetadata = {
        orgId: orgId,
        path: this.uploadPath,
        heading: this.categoryName,
        uploadedBy: this.store.selectSnapshot(state => state.auth.user.id),
        uploadedByName: userName,
      };

      this.fileUploadService
        .uploadSingleFile(file, metadata)
        .pipe(
          take(1),
          map((response: UploadedFileResponse) => {
            const fileDetails: UploadedFileType = {
              fileName: response.name,
              fileType: response.contentType,
              customFileName: response.originalFileName || '',
              orgUserId: response.uploadedBy || '',
              uploadedDate: new Date(response.uploadedTimeStamp).toISOString(),
              fileId: response.id,
              isActive: false,
              isUploaded: true,
              heading: response.heading,
              pickedFrom: response.pickedFrom,
              pickedDevice: response.pickedDevice,
              latitude: response.latitude,
              longitude: response.longitude,
              locationName: response.locationName,
              uploadedBy: response.uploadedBy,
              uploadedByName: response.uploadedByName,
            };

            this.fileUploaded.emit({ category: this.categoryName, file: fileDetails });
          })
        )
        .subscribe({
          next: () => {
            console.log('File uploaded successfully');
          },
          error: error => {
            console.error('Error uploading file:', error);
          },
        });
      // clear the input elements all files
      this.fileInput.nativeElement.value = '';
    }
  }

  setActiveFile(file: UploadedFileType): void {
    this.files = this.files.map(f => ({
      ...f,
      isActive: f.fileId === file.fileId,
    }));
  }

  saveFile() {
    if (this.saveOneFileMode) {
      return (file: UploadedFileType) => {
        this.fileSaved.emit(file);
      };
    }
    return undefined;
  }
}
